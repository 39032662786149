import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSection2 = () => {
  let tltwo1 = new TimelineMax({ immediateRender: false });
  let tltwo2 = new TimelineMax({ immediateRender: false });

  tltwo1.from("#two img", 1, { scale: 1.5 });

  tltwo2.from("#two h1", 0.4, { x: -30, opacity: 0 });
  tltwo2.from("#two p", 0.4, { x: -20, opacity: 0 });

  const scenetwo1 = new ScrollMagic.Scene({
    triggerElement: "#one",
    triggerHook: "onLeave",
    duration: "200%",
  })
    // .setPin("#two")
    .setTween(tltwo1)
    .addTo(controller);

  const scenetwo2 = new ScrollMagic.Scene({
    triggerElement: "#two",
  })
    .setTween(tltwo2)
    .addTo(controller);
};

export default animateSection2;
