const getAnalytics = () => {
  const food_item_count = document.getElementById("food_item_count");
  const co2_emission_count = document.getElementById("co2_emission_count");

  fetch("https://api.nosh.tech/api/static/emissions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((Response) => Response.json())
    .then(({ message }) => {
      const { totalCarbon, totalQuantity } = message;

      food_item_count.innerHTML = totalQuantity || "100,077";
      co2_emission_count.innerHTML = totalCarbon || "31.02";
    })
    .catch((err) => console.log({ err }));
};

export default getAnalytics;
