import mobileMenuTrigger from "./hamburger";
import animateSection1 from "./scrollanimation1";
import animateSection2 from "./scrollanimation2";
import animateSection3 from "./scrollanimation3";
import animateSection4 from "./scrollanimation4";
import animateSection5 from "./scrollanimation5";
import animateNoshShop from "./scrollanimationNoshShop";
import animateSectionwhy from "./scrollanimationwhy";
import animateSectionabout from "./scrollanimatioabout";
import animateSectioncontri from "./scrollanimationcontri";
import entryanimation from "./entryanimation";
import buttonAnimation from "./downloadButtonAnimation";
import getAnalytics from "./analyticsFetch";

import ScrollMagic from "scrollmagic";

const controller = new ScrollMagic.Controller();
entryanimation();
mobileMenuTrigger();
buttonAnimation();

animateSection1();
animateSection2();
animateSection3();
animateSection4();
animateSection5();
animateNoshShop();
animateSectionwhy();
animateSectioncontri();

animateSectionabout();

getAnalytics();
