import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSection3 = () => {
  let tlthree1 = new TimelineMax({ immediateRender: false });

  tlthree1
    .from("#three img", 0.8, { y: 50, opacity: 0, scale: 0.6 })
    .from("#three h1", 0.4, { x: -10, opacity: 0 }, "=-.6")
    .from("#three p", 0.4, { x: 5, opacity: 0 }, "=-.4");

  const scenethree1 = new ScrollMagic.Scene({
    triggerElement: "#three",
  })
    .setTween(tlthree1)
    .addTo(controller);
};

export default animateSection3;
