import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSection4 = () => {
  let tlfour1 = new TimelineMax({ immediateRender: false });

  tlfour1
    .from("#four img", 0.5, { x: 50, opacity: 0, scale: 1.1 })
    .from("#four .text", 1, { x: -50, opacity: 0 }, "=-.6");

  const scenefour1 = new ScrollMagic.Scene({
    triggerElement: "#four",
  })
    .setTween(tlfour1)
    .addTo(controller);
};

export default animateSection4;
