import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSectionabout = () => {
  // const controller = new ScrollMagic.Controller();
  let tlab = new TimelineMax({ immediateRender: false });
  let tl2 = new TimelineMax({ immediateRender: false });

  tlab.from("#about #second", 0.8, { y: 50, opacity: 0 });
  tlab.from("#about #first", 0.8, { y: 50, opacity: 0 }, "=-.6");
  tlab.from("#about #third", 0.8, { y: 50, opacity: 0 }, "=-.5");

  const sceneab = new ScrollMagic.Scene({
    triggerElement: "#about",
  })
    .setTween(tlab)
    .addTo(controller);
};

export default animateSectionabout;
