import { TimelineMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateNoshShop = () => {
  let tlNoshShop = new TimelineMax({ immediateRender: false });

  tlNoshShop.from("#nosh-shop .text", 0.5, { opacity: 0, x: -10 }).from(
    "#nosh-shop img",
    0.5,
    {
      opacity: 0,
      scale: 0.9,
    },
    "=-0.2"
  );

  const scenefive1 = new ScrollMagic.Scene({
    triggerElement: "#nosh-shop",
  })
    .setTween(tlNoshShop)
    .addTo(controller);
};

export default animateNoshShop;
