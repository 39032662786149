import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSectioncontri = () => {
  let tlcontri1 = new TimelineMax({ immediateRender: false });

  tlcontri1.from(".contributor-cont", 0.5, { y: 100, opacity: 0 });

  const scenecontri1 = new ScrollMagic.Scene({
    triggerElement: "#contributors",
  })
    .setTween(tlcontri1)
    .addTo(controller);
};

export default animateSectioncontri;
