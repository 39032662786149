const buttonAnimation = () => {
  const downloadBtns = document.querySelectorAll(".download-href");
  const buttons = document.querySelectorAll(".download-buttons");

  downloadBtns.forEach((downloadBtn) => {
    downloadBtn.addEventListener("click", () => {
      buttons.forEach((button) => {
        button.classList.add("clicked");
        setTimeout(() => button.classList.remove("clicked"), 300);
      });
    });
  });
};

export default buttonAnimation;
