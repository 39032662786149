const hamburger = document.querySelector(".hamburger");
const drawer = document.querySelector(".drawer");
const main = document.querySelector("main");
const header = document.querySelector("header");
const buttons = document.querySelectorAll("nav.mob-nav a");

const mobileMenuTrigger = () => {
  hamburger.addEventListener("click", () => {
    if (hamburger.classList.contains("open")) {
      removeOpenClass();
    } else {
      hamburger.classList.add("open");
      drawer.classList.add("open");
      main.classList.add("open");
      setTimeout(() => header.classList.add("open"), 300);
    }
  });

  buttons.forEach((button) =>
    button.addEventListener("click", () => removeOpenClass())
  );
};

const removeOpenClass = () => {
  hamburger.classList.remove("open");
  drawer.classList.remove("open");
  main.classList.remove("open");
  header.classList.remove("open");
};

export default mobileMenuTrigger;
