import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSectionwhy = () => {
  let tlwhy1 = new TimelineMax({ immediateRender: false });

  tlwhy1.fromTo("#why-nosh .text", 0.2, { y: -400 }, { y: 100 });

  const scenewhy1 = new ScrollMagic.Scene({
    triggerElement: "#about",
    triggerHook: "onLeave",
    duration: "200%",
  })
    .setTween(tlwhy1)
    .addTo(controller);
};

export default animateSectionwhy;
