import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSection5 = () => {
  let tlfive1 = new TimelineMax({ immediateRender: false });

  tlfive1.from("#five .text", 0.5, { opacity: 0, x: -20 }).from(
    "#five img",
    0.5,
    {
      transform: "rotateX(70deg)",
      opacity: 0.1,
      scale: 0.5,
    },
    "=-0.2"
  );

  const scenefive1 = new ScrollMagic.Scene({
    triggerElement: "#five",
  })
    .setTween(tlfive1)
    .addTo(controller);
};

export default animateSection5;
