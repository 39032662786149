import { TimelineMax, TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import controller from "./animationcontroller";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const animateSection1 = () => {
  let tlone1 = new TimelineMax();
  let tlone2 = new TimelineMax({ immediateRender: false });

  tlone1.fromTo("#one img", 2, { y: 400 }, { y: -50 });

  tlone2.from("#one h1", 0.4, { x: -30, opacity: 0 });
  tlone2.from("#one p", 0.4, { x: -20, opacity: 0 });

  const sceneone1 = new ScrollMagic.Scene({
    triggerElement: "#download",
    triggerHook: "onLeave",
    duration: "200%",
  })
    .setTween(tlone1)
    .addTo(controller);

  const sceneone2 = new ScrollMagic.Scene({
    triggerElement: "#one",
  })
    .setTween(tlone2)
    .addTo(controller);
};

export default animateSection1;
