import { TimelineMax, TweenMax } from "gsap";

const entryanimation = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const tl = new TimelineMax();

    tl.from("div.nosh-background", 0.4, { y: -100, opacity: 0 }, "=.5")
      .from(" .tagline", 0.4, { y: -50, opacity: 0 }, "=-.2")
      .from(" .sub-tagline", 0.4, { y: -50, opacity: 0 }, "=-.2")
      .from(".download-links", 0.4, { y: -50, opacity: 0 }, "=-.2")
      .fromTo(
        ".learn-cont",
        0.4,
        { y: -100, opacity: 0 },
        { y: -50, opacity: 1 },
        "=-.2"
      );
  });
};

export default entryanimation;
